import { HTTP } from "../../services/axios";

export default {
  state: {
    loading: false,
    application: null
  },
  getters: {
    get_application: (state) => {
        return state.application
    }
  },
  mutations: {
    UPDATE_APPLICATION: ( state, data )=> {
        state.application = data
    }
  },
  actions: {
    async update_application({ commit }, payload){
      console.log("Inside dispatch")
      console.log("Payload", payload);
      return new Promise((resolve, reject) => {
        HTTP.get("finance/v2/bajaj/app/"+payload.id)
        .then((resp)=> {
            let { data } = resp
            data = data.data
            commit("UPDATE_APPLICATION", data) 
            resolve(resp);
        })
        .catch((err)=>{
            console.err(err);
            reject(err);
        })
      });
    }
  },
};